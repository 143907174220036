.font1 {
  font-size: 16px !important;
  line-height: 20px !important;
}
p + ul {
  margin-block-start: 15px;
}
p + ol {
  margin-block-start: 15px;
}
.hideDiv {
  display: none;
}
.rowFlex {
  display: flex;
}
span img {
  cursor: pointer;
}
div.sectionContainer:empty {
  display: none;
}
div.sectionContainer:empty + div.sectionContainer:not(:empty) {
  margin-top: 37px !important;
}
.columnFlex {
  display: flex;
  flex-direction: column;
}
.chkbox {
  margin: 0px 13px 0px 0px;
  pointer-events: all;
  border: 1px solid #94949a;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 2px;
  min-width: 24px;
}
.textBox {
  background-color: #fff;
}
/*----Primary Button Style --- */
.buttonStyle:hover {
  background-color: #1e64d0 !important;
  color: #ffffff !important;
  border-radius: 2px !important;
}

.buttonStyle:focus:active {
  background-color: #002b8c !important;
  border: 3px solid #87b7f3 !important;
  border-radius: 2px !important;
}
.buttonStyle:focus:hover {
  background-color: #1e64d0 !important;
  border: 3px solid #87b7f3 !important;
  border-radius: 2px !important;
}
.buttonStyle:active {
  background-color: #002b8c !important;
  border-radius: 2px !important;
}
.buttonStyle:focus {
  background-color: #0049bd !important;
  border-radius: 2px !important;
  border: 3px solid #87b7f3 !important;
}
.buttonStyle:disabled {
  background: #e4e4e8 !important;
  border-radius: 2px !important;
}
.buttonStyle:disabled:focus {
  background: #e4e4e8 !important;
  border-radius: 2px !important;
  border: 3px solid #94949a !important;
}
/*----Secondary Button Style --- */
.secondaryButtonStyle:hover {
  background: #5d96e6 !important;
  border: 1px solid #0049bd !important;
  border-radius: 2px !important;
  color: #ffffff !important;
}
.secondaryButtonStyle:focus:active {
  background-color: #1e64d0 !important;
  border: 1px solid #002b8c !important;
  border-radius: 2px !important;
  color: #ffffff !important;
}
.secondaryButtonStyle:focus:hover {
  background: #5d96e6 !important;
  border: 1px solid #0049bd !important;
  border-radius: 2px !important;
  box-sizing: border-box;
  outline-color: #d1e9ff !important;
  outline-style: solid !important;
  outline-width: medium !important;
  color: #ffffff !important;
}
@media screen and (max-width: 960px) {
  .secondaryButtonStyle:focus:hover {
    background: #ffffff !important;
    color: #0049bd !important;
  }
}
.secondaryButtonStyle:active {
  background: #1e64d0 !important;
  border: 1px solid #002b8c !important;
  border-radius: 2px !important;
  outline-color: #d1e9ff !important;
  outline-style: solid !important;
  outline-width: medium !important;
  color: #ffffff !important;
}
.secondaryButtonStyle:focus {
  background: #ffffff !important;
  border: 3px solid #abd1fb !important;
  border-radius: 2px !important;
}
/*** --- teritarybuttonstyle --- ****/
.teritaryButtonStyle:hover {
  background: #d1e9ff !important;
  border-radius: 2px !important;
}
.teritaryButtonStyle:focus:active {
  background: #abd1fb !important;
  border: 3px solid #5d96e6 !important;
  border-radius: 2px !important;
  color: #002b8c !important;
}
.teritaryButtonStyle:focus:hover {
  background: #d1e9ff !important;
  border: 3px solid #87b7f3 !important;
  border-radius: 2px !important;
}
.teritaryButtonStyle:active {
  background: #abd1fb !important;
  border-radius: 2px !important;
  color: #002b8c !important;
}
.teritaryButtonStyle:focus {
  background: #ffffff !important;
  border: 3px solid #5d96e6 !important;
  border-radius: 2px !important;
}
.ddnOptions {
  font-size: 16px;
  line-height: 20px;
  color: #323234;
}
.arrowicon {
  width: 15.22px !important;
  height: 8.57px !important;
  top: 16.71px !important;
  min-width: auto !important;
  padding: 0 !important;
  margin-left: 12.39px !important;
}
.svgAlign {
  text-align: center;
  align-self: center;
  margin-left: 12.39px;
  cursor: pointer;
  width: 15.22px;
  height: 8.57px;
}
.svgAlignTrt {
  text-align: center;
  align-items: center;
  cursor: pointer;
  width: 17.77px;
  height: 10px;
  margin-right: 13.12px;
}
/*-----------------------header & footer---------------------------------------*/
.appBar {
  position: static !important;
  background-color: #ffffff !important;
}
.footerBar {
  background-color: #323234 !important;
}
.logo {
  float: left;
  width: 44px;
  height: 23.83px;
  display: inline-block;
  vertical-align: center;
}
.logomwh {
  float: left;
  height: 80px;
  display: inline-block;
  vertical-align: center;
}
.footerLogo {
  color: white;
  height: 20px;
  padding: 10px 10px 10px 0px;
  border-bottom: 1px;
  text-decoration: none;
}
.vertical1 {
  float: left;
  width: 1px;
  height: 26px;
  margin: 0 15px;
  display: inline-block;
  background: #b4b4;
}
.title {
  color: #323234;
  float: left;
  width: auto;
  display: inline-block;
  flex-grow: 1;
  font-size: 24px !important;
  line-height: 24px !important;
  padding-top: 2px;
  text-decoration: none;
}
.toolbar {
  margin: 0 10%;
  height: 104px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.rightBox {
  display: flex;
  max-width: 195px;
  text-align: right;
  justify-content: flex-end;
}
.mainContainer {
  margin: 0px 10% !important;
  width: 100%;
  flex-wrap: wrap;
}
.copyRight {
  display: inline;
}
.linksContainerLeft {
  float: left;
  width: 60%;
  display: flex;
  font-size: 80%;
  font-style: normal;
  align-items: center;
  font-weight: normal;
  line-height: 180%;
  flex-direction: row;
  justify-content: flex-start;
}
.linksContainerRight {
  float: right;
  width: 40%;
  display: flex;
  font-size: 80%;
  font-style: normal;
  align-items: center;
  font-weight: normal;
  line-height: 180%;
  flex-direction: row;
  justify-content: flex-end;
}
.whiteSocialLinks {
  color: white;
  float: right;
  height: 20px;
  display: inline;
  padding: 10px 0px 10px 10px;
}
/*-----------------------home styles----------------------------------*/
.disclaimerBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 72px 0px 92px;
  max-width: 100%;
  min-height: 180px;
}
.disclaimerFloatingBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 72px 0px 182px;
  max-width: 100%;
  min-height: 180px;
}

.disclaimerText {
  max-width: 100%;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
}
.titleStyle {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #656569;
}

.fontStyle1 {
  font-family: '3MCircularTT';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.homeHeader {
  max-width: 453.75px;
  height: 165px;
  font-style: normal;
  font-weight: bold;
  line-height: 56px;
  color: #323234;
  padding: 78px 0 78px 40px;
}
.homePathQ {
  padding: 48px;
  margin: 0px;
  font-size: 24px;
  line-height: 40px;
  font-weight: normal;
  color: rgb(50, 50, 52);
}

.homeTPathBox {
  display: flex;
  flex-direction: column;
  padding: 48px;
}

.homeTPathVal {
  font-size: 40px !important;
  font-weight: bold !important;
  line-height: 48px !important;
}
.homeTPathVal h1 {
  font-size: 40px;
}
.tpathDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: auto;
}
.infoSpan {
  margin: 0px 12px;
}
.homeTPathDescVal {
  margin: 0px;
  max-width: 644px;
  min-height: 40px;
  font-size: 16px;
  line-height: 20px;
  color: rgb(50, 50, 52);
  padding: 24px 0px;
}
.homeBtnBox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
}
.hometext {
  margin: -5px;
}
.homeBtnGrp {
  height: 48px !important;
  padding: 14px 16px !important;
  font-size: 16px !important;
  min-width: 220px !important;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: 20px !important;
  margin-right: 16px !important;
  border-radius: 0 !important;
  margin-bottom: 10px !important;
}

.homeAllTrtBox {
  padding-top: 28px;
}
.homeSafetyStmt {
  margin: 0px;
  font-size: 12px;
  line-height: 16px;
  color: rgb(50, 50, 52);
  padding: 72px 0px 92px;
}
.description {
  margin-top: 48px;
}
.getStartContainer {
  display: flex;
  background: #f8f8fa;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 20%), 0px 1px 1px rgb(0 0 0 / 14%),
    0px 2px 1px rgb(0 0 0 / 12%);
  max-height: 312px;
  border-radius: 4px;
}
.homeContainer {
  margin: 0 10%;
  min-height: calc(100vh - 145px);
}
.sftyStmtContainer {
  display: flex;
  padding: 0;
}
/*-----------------------stepper styles----------------------------------*/
.stepDiv {
  margin-top: 0px;
  display: flex;
  flex-direction: row;
}
.stepContainer {
  max-width: 200px;
  min-width: 200px;
  min-height: 600px;
}
.stepNumber {
  display: none;
}
.stepPrevNextBtnBox {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}
.stepBrowseAll {
  display: none;
}
.stepRoot {
  margin: 0 10%;
}
.stepBreadcrumb {
  padding-top: 15px;
}
.stepHeadTitle {
  font-size: 48px !important;
  margin-top: 24px !important;
  font-weight: bold !important;
  line-height: 56px !important;
}
/*------------------------------attribute pages---------------------------------------*/
.attributeContainer {
  margin: 4em;
}
.attQtn {
  font-size: 32px !important;
  font-weight: bold !important;
  line-height: 36px !important;
  margin-bottom: 10px !important;
}
.attQtnDesc {
  font-size: 16px !important;
  font-weight: normal !important;
  line-height: 20px !important;
}
.paramContainer {
  margin: 16px 24px !important;
}
.enterBtnBox {
  margin: 0 0.5em;
}
.nxtBtnBox {
  margin: 0 16px !important;
}
.prvBtnBox {
  width: 100%;
}
/*----------------------size page----------------------------------------------*/
.sizeRoot {
  padding: 24px;
  flex-grow: 1;
}
/*-------------------------appearance page-------------------------------------*/
.allAppearanceBox {
  margin: 16px 0;
  display: flex;
  padding: 16px 24px 0px;
  max-width: 713px;
  align-items: flex-start;
  flex-direction: column;
}
.descriptionBox {
  width: inherit;
  display: flex;
  padding: 0;
  /* max-width: 524px; */
  min-height: 94px;
  align-items: flex-start;
  flex-direction: column;
}
.appearanceMsgBox {
  width: 304px;
  display: flex;
  justify-content: flex-end;
}
.warningBox {
  margin: 16px 0;
  display: flex;
  padding: 32px;
  max-width: 732px;
  background: #ffffff;
  min-height: 268px;
  align-items: flex-start;
  flex-direction: column;
}
.appearanceBox {
  width: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  margin-bottom: 24px;
  flex-direction: row;
}
.appearanceContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 9px;
}
/*-----------------------recommendation(all path) styles----------------------------------*/
.trtContainer {
  width: 100%;
  margin-top: 40px;
}
.recommRoot {
  margin: 0 10%;
}
.largeButtonStyle {
  margin: 48px 0 0px 0;
}
.charactersticsStyle {
  display: flex;
  margin-top: 35px;
  flex-direction: row;
}
.mainContentStyle {
  padding-left: 0px;
}
.bottomSlider {
  margin-top: 61px;
}
.switchContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 61px;
}
.sectionDesc {
  max-width: auto;
  margin-top: 12px;
}
.trtImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
  background: rgb(248, 248, 250);
  padding: 0px 18px;
  margin: 0px 29px;
}
.trtCheckboxContainer {
  display: flex;
  flex-direction: column;
  max-height: 120px;
  align-items: center;
  justify-content: center;
}
.trtCheckbox {
  background: rgb(255, 255, 255);
  min-width: 22px;
  min-height: 22px;
  border: 1px solid rgb(180, 180, 184);
  border-radius: 1px;
  padding: 0px;
}
.btnFinalize {
  padding: 14px 16px 14px 16px !important;
  border-radius: 2px !important;
  width: 347px !important;
  height: 48px !important;
}
.btnFinalizeAllTherapy {
  padding: 10px 35px 14px 35px !important;
  border-radius: 2px !important;
}
.charctersticsBoxStyle {
  width: 308px;
}
.pageTitle {
  max-width: auto;
  min-height: 20px;
  margin-top: 16px;
}
.pageIntroStyle {
  max-width: auto;
  min-height: 20px;
  margin-top: 56px;
}
.pageDesc {
  font-size: 16px;
  line-height: 20px;
  margin-top: 16px;
}
.allTherapyContent {
  margin-top: 72px;
}
.section {
  margin-top: 30px;
}
.sectionName {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 32px !important;
  line-height: 36px !important;
  color: rgb(50, 50, 52) !important;
}
.trtDescriptor {
  min-height: 24px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: rgb(50, 50, 52) !important;
  align-self: stretch !important;
  flex-grow: 0 !important;
  order: 1 !important;
  margin-top: 12px !important;
}
.treatments {
  display: flex;
  flex-direction: column;
}
.firstSecContainer {
  margin-top: 30px;
}
.sectionContainer {
  width: 100% !important;
  padding-top: 0px !important;
  margin-top: 64px;
}
.reccArrowIcon {
  width: 17.76px !important;
  height: 10px !important;
  min-width: auto !important;
  padding: 0 !important;
  margin-left: 12.39px !important;
}
/*-----------------------goal pathway styles----------------------------------*/
.goalHeader {
  font-size: 48px;
  margin-top: 24px;
  font-weight: 700;
}
.breadCrumbStyle {
  padding-top: 15px;
}
.goalIntro {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}
.goalCard {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 72px 72px 120px;
  border-radius: 4px;
  min-height: 626px;
  background-color: rgb(248, 248, 250);
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px, rgb(0 0 0 / 14%) 0px 1px 1px,
    rgb(0 0 0 / 12%) 0px 2px 1px;
}
.goalDrpdwnBox {
  display: flex;
  margin-top: 47px;
  flex-direction: row;
}
.goalBtnBox {
  margin-top: 50px;
}
.goalLegalText {
  max-width: 1040px;
  min-height: 92px;
  margin-top: 69px;
}
/*-----------------------Product Details styles----------------------------------*/
.buttonPropsStyle {
  flex-direction: row;
  align-items: center;
  padding: 14px 20px;
  width: 480px;
  height: 48px;
  border-radius: 2px;
}
.blueBorderBlockWithImage {
  width: 250px;
  height: auto;
  border: 1px solid #0049bd;
  padding: 15px;
  border-radius: 5px;
}
.rightColumnStyle {
  display: flex;
  flex-direction: column;
}
.leftColumnStyle {
  margin-right: 32px;
}

.loaderContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.7);
}

.spinner {
  animation: rotator 1.4s linear infinite;
  position: fixed;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}
@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 140;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 2.8s ease-in-out infinite;
}
.recommFloatBar {
  position: fixed !important;
  top: auto !important;
  bottom: 0 !important;
  background-color: #fff !important;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14),
    0px 1px 14px rgba(0, 0, 0, 0.12) !important;
  z-index: 1 !important;
  height: 96px;
}
.recommToolBar {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
  align-items: center !important;
  padding: 16px 80px !important;
}
.finalButtonTextStyle {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: 20px !important;
}
.chkboxInd {
  margin: 0px 13px 0px 0px;
  pointer-events: all;
  border: 1px solid #94949a;
  cursor: pointer;
  border-radius: 2px;
}
.chkboxIndTrt {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(180, 180, 184);
  border-radius: 1px;
  padding: 0px;
  height: 20px;
  width: 20px;
}
.recomFooter {
  width: 100%;
  bottom: 90px;
  position: relative;
  flex-grow: 1;
  margin-top: 20px;
}
.allTrtText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: rgb(101, 101, 105);
  width: 148px;
  height: 20px !important;
}
.allTrtLabel {
  margin-right: 22px !important;
  pointer-events: none;
}
@keyframes colors {
  0% {
    stroke: #00a1cf;
  }
  50% {
    stroke: #0049bd;
  }
  100% {
    stroke: #00a1cf;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 140;
  }
  50% {
    stroke-dashoffset: 35;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 140;
    transform: rotate(450deg);
  }
}
