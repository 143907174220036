/*-----------------------finalized recomm(all path) styles----------------------------------*/
.btnContainer {
  margin-top: 48px;
  flex-direction: row;
}
.btnBoxLeft {
  padding: 0px !important;
  flex-grow: 1 !important;
  width: 100% !important;
}
.btnBoxRight {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: end;
}
.btnReturn {
  border-color: rgb(0, 73, 189);
  min-height: 48px;
}
.finalizeTrtmnts {
  margin-top: 40px;
}
