.bounding-box {
  min-height: 300px;
  background: #f8f8fa;
  padding: 15px 48px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.goalbounding-box {
  background: #f8f8fa;
  padding: 32px 48px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(122, 111, 111, 0.12);
  border-radius: 4px;
}
.characteristics {
  margin-top: 20px;
  column-count: 3;
  column-gap: 40px;
  min-height: 300px;
}
.goalcharacteristics {
  display: flex;
  column-gap: 320px;
}

h4 {
  font-size: 18px;
  font-weight: 800;
}

dl {
  padding: 16px 0px;
  display: grid;
  grid-template-columns: 40fr 60fr;
  border-top: 1px solid #ccccd0;
  -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
  page-break-inside: avoid; /* Firefox */
  break-inside: avoid; /* IE 10+ */
  margin: 0px;
  box-sizing: border-box;
}

dt {
  grid-column: 1;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  line-height: 20px;
  color: #656569;
  font-size: 16px;
}

dd {
  grid-column: 2;
  font-weight: 800;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  line-height: 20px;
  font-size: 16px;
}
.goaldd {
  font-weight: 800;
  margin: 1px 18px;
  padding: 0px;
}
.goaldl {
  padding: 16px 0px;
  display: grid;
  margin: 0px;
  box-sizing: border-box;
  grid-template-columns: none;
  border-top: 0px;
}

@media only screen and (max-width: 1024px) {
  .bounding-box {
    padding: 32px;
  }
  .characteristics {
    column-count: 2;
  }
  .goalcharacteristics {
    column-gap: 0px;
  }
}
@media only screen and (max-width: 945px) {
  .bounding-box {
    min-height: auto;
  }
  .characteristics {
    min-height: auto;
  }
}
@media only screen and (max-width: 600px) {
  .bounding-box {
    padding: 24px;
    min-height: auto;
  }
  .characteristics {
    column-count: 1;
    min-height: auto;
  }
  .goalbounding-box {
    padding: 24px;
  }
  .goalcharacteristics {
    margin-top: 20px;
    column-count: 1;
    column-gap: 40px;
    display: block;
    min-height: auto;
  }
}
