.heading {
  padding-top: 20px;
}

.react-datepicker-wrapper {
  display: block;
  width: 100%;
  padding-bottom: 15px;
  border: 1px solid #9c0000;
}

.datefBirthMainGrid .label-style {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #76767a;
  margin-bottom: 5px;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 18.5px 14px;
  font-size: 16px;
  border: 1px solid #ccc;
  height: auto;
  border-radius: 2px;
  box-sizing: border-box;
  max-width: 573px;
}

@media only screen and (max-width: 926px) {
  .react-datepicker__input-container input {
    width: 100%;
    padding: 14px;
    font-size: 16px;
    border: 1px solid #ccc;
    height: auto;
    border-radius: 2px;
    box-sizing: border-box;
    max-width: 573px;
  }
}

.calanderStyle {
  width: 20px;
}
.failuremessage {
  font-size: 23px;
  color: #9c0000;
  padding-top: 10px;
  padding-bottom: 20px;
}

@media (min-width: 1024px) {
  .failuremessage {
    font-size: 23px;
    color: #9c0000;
    padding-left: 7px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
}

.successmessage {
  font-size: 23px;
  color: green;
  padding-top: 10px;
  padding-bottom: 20px;
}
@media (min-width: 1024px) {
  .successmessage {
    font-size: 23px;
    color: green;
    padding-top: 10px;
    padding-left: 7px;
    padding-bottom: 20px;
  }
}
.datePickerGrid .error {
  border: 1px #9c0000 solid;
}
.datefBirthMainGrid .error-message-label {
  color: #9c0000;
  font-size: 13px;
}
.grid-item {
  padding-left: 12px;
}
.submit-button .enterBtnBox {
  padding: 15px 0 10px;
  margin: 0px;
}
@media only screen and (min-width: 1024px) {
  .button-Style .submit-button {
    position: relative;
    padding: 20px 0 10px;
  }
}

@media only screen and (min-width: 667px) {
  .button-Style .submit-button {
    position: relative;
    padding: 20px 0 10px;
    width: 573px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 912px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (min-width: 912px) {
  .button-style {
    padding-right: 20px;
  }
}
