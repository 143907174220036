@media (max-width: 926px) {
  /*-----------------------common styles----------------------------------*/
  .hide {
    display: none;
  }
  .mobGrid {
    padding: 10px !important;
  }
  div.sectionContainer:empty + div.sectionContainer:not(:empty) {
    margin-top: 48px !important;
  }
  div.sectionDesc + div.treatment-descriptor div.mobTrtContainer {
    margin-top: 16px !important;
  }
  .mobPadding {
    padding: 0 20px;
  }
  .mobMargin {
    margin: 0 20px !important;
  }
  h1 {
    font-size: 24px !important;
  }
  .mobFontStyle1 {
    font-family: '3MCircularTT';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }
  .mobFont1 {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .mobChkbox {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }

  .mobRowFlex {
    display: flex;
  }
  /*------------------------header-----------------------------------*/
  .mobAppBar {
    padding: 0 !important;
  }
  .mobLogo {
    width: 36px;
    height: 19.5px;
  }

  .mobLogomwh {
    width: 49px;
    height: 44px;
  }

  .mobVertical1 {
    height: 20px;
  }
  .mobTitle {
    width: auto;
    height: auto;
    padding: 0;
    font-size: 20px !important;
    line-height: 24px !important;
  }
  .mobToolbar {
    margin: 0;
    height: auto;
    min-height: 44px !important;
    padding: 0 0 0 20px;
  }
  .mobRightBox {
    display: none;
  }
  .mobMainContainer {
    margin: 24px 20px !important;
    display: flex;
    padding: 0px;
    flex-direction: column;
  }
  .mobCopyRight {
    padding: 0px;
  }
  .mobLinksContainerLeft {
    width: 100%;
    justify-content: space-between;
  }
  .mobLinksContainerRight {
    width: 100%;
    justify-content: space-between;
  }
  .mobWhiteSocialLinks {
    font-size: 12px;
    line-height: 20px;
    padding: 0;
  }
  .mobFooterLogo {
    padding: 0px;
  }
  .mobHamMenu {
    cursor: pointer;
    display: flex;
  }
  /*-----------------------home styles----------------------------------*/
  .mobDisclaimerBox {
    margin: 40px 20px;
  }
  .mobDisclaimerFloatingBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 20px 130px;
    max-width: 100%;
    min-height: 180px;
  }
  .mobHomeHeader {
    padding: 0;
    font-size: 24px;
    line-height: 28px;
    height: auto;
    width: 100%;
  }
  .mobHomePathQ {
    margin: 24px 20px;
    padding: 0;
    font-size: 16px;
    line-height: 20px;
  }
  .mobHomeTPathBox {
    padding: 0;
    width: 100%;
  }
  .mobHomeTPathVal {
    font-size: 20px !important;
    line-height: 24px !important;
  }
  .mobTPathDiv {
    margin: 24px 0;
  }
  p.mobHomeTPathVal h1 {
    font-size: 20px !important;
  }
  .mobInfoSpan {
    margin: 0px 4px;
    position: relative;
    bottom: -5px;
  }
  .mobHomeTPathDescVal {
    padding: 0;
    font-size: 16px;
    line-height: 20px;
  }
  .mobHomeBtnBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    margin: 24px 0;
  }
  .mobHomeBtnGrp {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 14px 16px !important;
    background: #0049bd !important;
    border-radius: 2px !important;
    width: 100% !important;
  }
  .mobHomeAllTrtBox {
    padding: 0;
  }
  .mobHomeSafetyStmt {
    font-size: 12px;
    line-height: 16px;
    padding: 40px 0 64px 0;
  }
  .disclaimerBox {
    margin: 48px 20px 64px 20px;
  }
  .mobDescription {
    display: flex;
    min-height: 208px;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 0px;
  }
  .mobGetStartContainer {
    padding: 0 20px;
    min-height: 372px;
    align-items: flex-start;
    flex-direction: column;
    max-height: 500px;
  }
  .mobHomeContainer {
    margin: 0px;
    padding: 0;
  }
  .mobSftyStmtContainer {
    padding: 0 20px;
  }
  /*-----------------------stepper styles----------------------------------*/
  .mobStepDiv {
    margin-top: 0px;
  }
  .mobStepContainer {
    display: none;
  }
  .mobStepNumber {
    display: flex;
    align-items: center;
  }
  .mobStepVal {
    height: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #323234;
  }
  .mobStepPrevNextBtnBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }
  .mobStepBrowseAll {
    display: flex;
    align-self: center;
    margin-top: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #323234;
  }
  .mobStepRoot {
    margin: 0;
  }
  .mobStepBreadcrumb {
    display: none;
  }
  .mobStepHeadTitle {
    font-size: 24px !important;
    line-height: 28px !important;
    margin-bottom: 16px !important;
  }
  /*------------------------------attribute pages---------------------------------------*/
  .mobAttributeContainer {
    margin: 24px 20px;
  }
  .mobAttQtn {
    font-weight: bold !important;
    font-size: 24px !important;
    line-height: 28px !important;
  }
  .mobEnterBtnBox {
    margin: 0;
    width: 100%;
  }
  .mobParamContainer {
    margin: 16px 0px !important;
  }
  .enterBtnBox button {
    width: 100% !important;
  }
  /*----------------------size page----------------------------------------------*/
  .mobTxtBox {
    padding: 0;
    flex-grow: 1;
  }
  .mobSizeTxtBoxContainer {
    margin: 16px 0 22px 0 !important;
  }
  .mobSizeRoot {
    padding: 0;
  }
  /*-------------------------appearance page-------------------------------------*/
  .mobAllAppearanceBox {
    margin: 0;
    display: flex;
    padding: 27px 0;
    max-width: 713px;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
  .mobDescriptionBox {
    min-height: auto;
  }
  .mobAppearanceMsgBox {
    align-self: flex-end;
  }
  .mobWarningBox {
    padding: 20px;
  }
  .mobAppearanceBox {
    flex-direction: column;
    margin-bottom: 26px;
  }

  .transitionClose {
    transition: max-height 0.15s ease-out;
    max-height: 0;
    visibility: hidden;
    display: block;
  }
  .transitionOpen {
    display: block;
    transition: max-height 0.15s ease-in;
  }

  /*-----------------------recommendation(all path) styles----------------------------------*/

  .mobTrtContainer {
    width: 100%;
    margin-top: 28px;
  }
  .mobRecommRoot {
    margin: 0;
  }
  .mobLargeButtonStyle {
    margin-top: 24px !important;
  }
  .mobCharactersticsStyle {
    flex-direction: column-reverse;
    display: flex;
    margin-top: 48px;
  }
  .mobCharactersticsFinStyle {
    flex-direction: column-reverse;
    display: flex;
    margin-top: 0px;
  }
  .mobMainContentStyle {
    padding-left: 0px;
  }
  .mobTrtCheckboxContainer {
    display: block;
  }
  .mobTrtCheckbox {
    min-width: 20px;
    min-height: 20px;
    margin-right: 12px;
  }
  .mobPrdCheckbox {
    min-width: 20px;
    min-height: 20px;
    margin-right: 0px;
  }
  .mobBtnFinalize {
    padding: 12px 14px !important;
    margin: 29px 0 0 0 !important;
    min-height: 48px !important;
  }
  .mobCharctersticsBoxStyle {
    width: 100%;
    margin-top: 48px;
  }
  .mobPageIntroStyle {
    margin-top: 32px !important;
  }
  .mobAllTherapyContent {
    margin-top: 48px !important;
  }
  .mobSection {
    margin-top: 48px;
  }
  .mobSectionName {
    font-size: 24px !important;
  }
  .mobTrtDescriptor {
    margin-top: 8px !important;
    min-height: 0 !important;
  }
  .mobTreatments {
    margin-bottom: 0px !important;
    width: 100%;
  }
  .mobFirstSecCont {
    margin: 0 !important;
  }
  .mobSectionContainer {
    margin-top: 72px !important;
  }
  div.firstSecContainer div.treatment-descriptor:first-child {
    margin-top: 16px !important;
  }
  .recommSvgAlign {
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  /*-----------------------finalized recomm(all path) styles----------------------------------*/
  .mobBtnContainer {
    flex-direction: column !important;
    margin-top: 16px;
  }
  .mobBtnBoxRight {
    display: flex !important;
    justify-content: center !important;
  }
  .mobBtnReturn {
    max-width: 100% !important;
    width: 100% !important;
  }
  .mobBottomSlider {
    display: none !important;
  }
  .mobFinalizeTrtmnts {
    margin-top: 0px;
    margin-bottom: 64px;
  }
  /*-----------------------goal pathway styles----------------------------------*/
  .mobGoalHeader {
    margin-top: 0;
  }
  .mobGoalIntro {
    line-height: 20px;
  }
  .mobGoalCard {
    margin-top: 10px;
    padding-top: 30px;
    min-height: 400px;
  }
  .mobGoalDrpdwnBox {
    flex-direction: column !important;
    margin-top: 59px;
  }
  .mobGoalBtnBox {
    margin: 0 0 24px 0;
  }
  .mobGoalLegalText {
    max-width: 1040px;
    min-height: 92px;
    margin-top: 69px;
  }
  /*-----------------------Product Details styles----------------------------------*/
  .mobButtonPropsStyle {
    width: 100%;
  }
  .mobBlueBorderBlockWithImage {
    width: 100%;
    padding: 24px 0 32px 0;
  }
  .mobRightColumnStyle {
    align-items: center;
  }
  .mobLeftColumnStyle {
    margin-right: 5px;
  }
  .recommMobileToolBar {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0px 0px 16px !important;
  }
  .finalMobButtonTextStyle {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: bold !important;
    line-height: 18px !important;
  }
  .mobTrtBar {
    position: relative;
    top: 17px;
  }
  .recomMobFooter {
    width: 100%;
    bottom: 105px;
    position: relative;
    flex-grow: 1;
    margin-top: 20px;
  }
  .recommMobFloatBar {
    position: fixed !important;
    top: auto !important;
    bottom: 0 !important;
    background-color: #fff !important;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14),
      0px 1px 14px rgba(0, 0, 0, 0.12) !important;
    z-index: 1 !important;
    height: 110px;
  }
  .allTrtMobText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: rgb(101, 101, 105);
    width: 129px;
    height: 18px !important;
  }
}
